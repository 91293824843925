export const constant = {
    //baseUrl: 'http://127.0.0.1:5001/',
    //newBaseUrl:'http://127.0.0.1:5000',
    baseUrl: 'https://api.videobanalo.com/',
    newBaseUrl:'https://emailapi.videobanalo.com',
    officetypeId:'671a2c02027ef0524084f756',
    companyId:'671a2bea027ef0524084f74d',
    industryId:'671a2bfb027ef0524084f753',
}

 